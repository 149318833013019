import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Plank 3×1:15 & Side Plank 3x:30/side`}</p>
    <p>{`Deadlifts 4×8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`For time, partitioned however you’d like:`}</p>
    <p>{`100-KB SDHP’s (53/35) (RX+ 70/53)`}</p>
    <p>{`100-Back Extensions (RX+ 25`}{`#`}{` plate to chest)`}</p>
    <p>{`150-Situps`}</p>
    <p>{`2000M Row`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is another free Partner Workout so bring a friend!
Classes at 9:00 & 10:15am. If you aren’t a member but would like to
reserve a spot please email: Daniel\\@crossfittheville.org.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      